import Script from 'next/script';

const NextDoorScript = () => (
  <>
    <Script strategy="lazyOnload" id="nextdoor-script">
      {`
          (function (win, doc) {
             if (win.ndp) return;
             var tr = win.ndp = function() {
                tr.handleRequest ? tr.handleRequest.apply(tr, arguments) : tr.queue.push(arguments);
             };
            tr.queue = [];
          })(window, document);
        `}
    </Script>
    <Script
      strategy="lazyOnload"
      src="https://ads.nextdoor.com/public/pixel/ndp.js"
      onLoad={() => {
        window?.ndp('init', 'cd52ec26-b903-46c0-a856-2bd0f4792e5a', {});
        window?.ndp('track', 'PAGE_VIEW');
      }}
    />
  </>
);

export default NextDoorScript;
